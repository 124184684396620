<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <ApplicationComponent/>

    </transition>
  </div>
</template>

<script>

import ApplicationComponent from '@/components/applications/ApplicationComponent.vue'

export default {
  name: 'Tender',
  props: ['setSection'],
  components: {
    ApplicationComponent
  },
  beforeMount() {
    this.setSection('APPLICATION')
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
