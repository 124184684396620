<template>
  <v-container>
	<div class="project-card">

		<h1>
			<span>№ 0173100009521000069</span>
		</h1>
		
		<section>

			<div class="tabs-wrap">

				<v-tabs>
					<v-tab>Информация</v-tab>
					<v-tab>Документы</v-tab>
				
				<v-tab-item>

						<div class="buttons">
						<v-btn outlined>Удалить</v-btn>
                        <a href="/tenderEdit">
							<v-btn>Редактировать</v-btn>
                        </a>		
						</div>

					<ul class="timeline">
						<li class="active"><div><p>Публикация</p><span>02.06.2021 <br>18:23</span></div></li>
						<li class="active"><div><p>Мониторинг</p><span>02.06.2021 <br>18:23</span></div></li>
						<li data-stage="" data-time=""><div><p>Подача заявок</p><span>02.06.2021 <br>18:23</span></div></li>	
						<li data-stage="" data-time=""><div><p>Оценка 1х частей</p><span>02.06.2021 <br>18:23</span></div></li>	
						<li data-stage="" data-time=""><div><p>Подача 2х частей</p><span>02.06.2021 <br>18:23</span></div></li>	
						<li data-stage="" data-time=""><div><p>Оценка 2х частей</p><span>02.06.2021 <br>18:23</span></div></li>	
					</ul>
			
			<div class="grid30">
				<div class="div1">
					<h4>заказчик</h4>
					<a href="#">МИНИСТЕРСТВО ПРОМЫШЛЕННОСТИ И ТОРГОВЛИ РОССИЙСКОЙ ФЕДЕРАЦИИ</a>
					<h4>тема</h4>
					<p>«Оказание услуг по экспертно-аналитическому сопровождению актуализации методических рекомендаций по разработке региональных программ развития торговли и форм официальной статистической отчетности,применяемых в сфере торговой деятельности», шифр «Региональная торговля».</p>
				</div>
				
				<div class="div2">
					<h2>Общая информация</h2>
					
					<div>
						<h4>начальная цена</h4>
						<h3>15 000 000,00 ₽</h3>
					</div>
					
					<div>
						<h4>закон</h4>
						<p>44-ФЗ</p>
					</div>
					
					<div class="width50">
					
						<div>
							<h4>уровень</h4>
							<p>Федеральный</p>
						</div>


						<div>
							<h4>субъект рф</h4>
							<p>город Москва</p>
						</div>
						
					</div>
					
					<div>
						<h4>процедура закупки</h4>					
						<p>Открытый конкурс в электронной форме</p>
					</div>
					
					<div>
						<h4>направление деятельности</h4>
						<p>Не указано</p>
					</div>
					
					<a href="#" class="btn outline">Тендер на портале zakupki.gov.ru</a>
					
				</div>				
				<div class="div3">
					<h2>Обеспечение</h2>
					
					<h4>заявка</h4>
					<div>
						<div><span class="rub"></span>150 000,00 ₽</div>
						<div><span class="percent"></span>1,00</div>
					</div>
					
					<h4>Исполнение</h4>
					<div>
						<div><span class="rub"></span>—</div>
						<div><span class="percent"></span>10,00</div>
					</div>
					
					<h4>гарантия</h4>
					<div>
						<div><span class="rub"></span>—</div>
						<div><span class="percent"></span>—</div>
					</div>
					
					
				</div>								
				<div class="div4">
					<h2>Результат</h2>
					
					<h4>Победитель</h4>
					<p>—</p>
					
					<h4>сумма контракта</h4>
					<p>—</p>
				</div>
				

			</div>
			
			<div class="grid30" style="margin-top: 20px">
				<div class="">
					<h2>Заявки</h2>
					
					<div class="columns2">
						<div>
							<h4>юридическое лицо</h4>
							<a href="#">НИСИПП</a>
						</div>

						

						<div>
							<h4>Формальная часть</h4>
							<a href="#">Горошилова М.М.</a>
						</div>

						<div>
							<h4>статус</h4>
							<span>Не начата</span>
						</div>

						<div>
							<h4>Содержательная часть</h4>
							<a href="#">Буев В.В.</a>
						</div>

						<div>
							<h4>обеспечение</h4>
							<span>Не внесено</span>
						</div>

						
											
					</div>
						<div>
							<v-btn outlined style="width: 100%; margin-top: 20px;">Редактировать</v-btn>
						</div>	
				</div>
				
				<div class="">
					<h2>Ссылки</h2>
					<p><a href="#">ссылка договор</a></p>
					<p><a href="#">ссылка на проект</a></p>
				</div>
			</div>
				</v-tab-item>

				<v-tab-item>
				<div class="files-library">
				
				<v-btn outlined class="add-files">Загрузить файлы</v-btn>
				
				<div class="files-list">


								<v-menu
									bottom
									center
								>
									<template v-slot:activator="{ on, attrs }">
									<div class="file" v-bind="attrs" v-on="on">
										<img src="~@/assets/tempfile1.png" width="120" height="120" alt="">
										<p>Название файла.jpg</p>
									</div>
									</template>

									<v-list>
									<v-list-item>
										<v-list-item-title>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M19 22H5V20H19V22ZM12 18L6 12L7.41 10.59L11 14.17V2H13V14.17L16.59 10.59L18 12L12 18Z" fill="#2E3646"/>
											</svg>
											Скачать
										</v-list-item-title>										
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M3.9 12C3.9 10.29 5.29 8.9 7 8.9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15.1H7C5.29 15.1 3.9 13.71 3.9 12ZM8 13H16V11H8V13ZM17 7H13V8.9H17C18.71 8.9 20.1 10.29 20.1 12C20.1 13.71 18.71 15.1 17 15.1H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z" fill="#2E3646"/>
											</svg>
											Получить ссылку
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M19 22L19 2L21 2L21 22L19 22Z" fill="#2E3646"/>
											<path fill-rule="evenodd" clip-rule="evenodd" d="M8 5L2.5 19H4.75L5.87 16H12.12L13.24 19H15.49L10 5H8ZM6.62 14L9 7.67L11.38 14H6.62Z" fill="#2E3646"/>
											</svg>

											Переименовать
										</v-list-item-title>										
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#2E3646"/>
											</svg>
											Удалить
										</v-list-item-title>
									</v-list-item>
									</v-list>
								</v-menu>


					
					<div class="file">
						<img src="~@/assets/tempfile2.png" width="120" height="120" alt="">
						<p>Название файла.pdf</p>
					</div>
				</div>
				
			</div>           

				</v-tab-item>
			
				

				</v-tabs>
			</div>
			
		</section>

		</div>
  </v-container>
</template>

<script>

export default {
  name: 'ApplicationComponent',
}
</script>

<style scoped>
 .v-tabs-items {
	overflow: visible;
 }
</style>
